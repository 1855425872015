import React, { useCallback, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";
import { LoginInfo } from "./types";

type IframeModalProps = {
  title: string;
  isOpen: boolean;
  uri: string;
  environment: string;
  handlModalIsOpen: any;
  handleLoginInfo?: (info: LoginInfo) => void;
  handleSignature?: (signature: string[]) => void;
};

const IframeModal = ({
  title,
  isOpen,
  uri,
  environment,
  handlModalIsOpen,
  handleLoginInfo,
  handleSignature,
}: IframeModalProps) => {
  const closeModal = useCallback(() => {
    handlModalIsOpen(false);
  }, [handlModalIsOpen]);

  useEffect(() => {
    window.addEventListener("message", (event: MessageEvent) => {
      if (event.data) {
        if (event.data.step && event.data.step === 3) {
          const info: LoginInfo = {
            userID: event.data.m_UserId,
            address: event.data.m_Address,
            accessTokenV1: event.data.m_AccessToken,
            accessTokenV2: "",
            environment: environment,
          };

          if (!!handleLoginInfo) {
            handleLoginInfo(info);
          }

          closeModal();
        } else if (event.data.Result === 0) {
          if (!!handleSignature) {
            handleSignature(event.data.m_Signature);
          }
        }
      }
    });
  }, []);

  return (
    <ModalContainer show={isOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UriSection>
          <UriLabel>URI | {uri}</UriLabel>
        </UriSection>
        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
        <iframe width="450px" height="500px" scrolling="no" src={uri} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={closeModal}>
          CLOSE
        </Button>
      </Modal.Footer>
    </ModalContainer>
  );
};

export default IframeModal;

const ModalContainer = styled(Modal)`
  position: releative;
`;

const UriSection = styled.div`
  width: 94%;
  height: 30px;
  margin-left: 0;
  background-color: #eeeeee;
  position: absolute;
  top: 30px;
`;

const UriLabel = styled.label`
  margin-left: 13px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
