import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Footer from "./Footer";
import SidebarItem from "./SidebarItem";
import { MenuItem } from "./types";

const Sidebar = () => {
  const menus: MenuItem[] = [
    { name: "QRAuth", path: "/" },
    { name: "Auth", path: "/auth" },
    // { name: "Tutorial", path: "tutorial" },
  ];

  return (
    <SidebarSection>
      <Link to="/">
        <SidebarTitle>Wemix Sample</SidebarTitle>
      </Link>
      {menus.map((menu, index) => (
        <SidebarItemContainer key={index + menu.name}>
          <SidebarItem menu={menu} />
        </SidebarItemContainer>
      ))}

      <Footer />
    </SidebarSection>
  );
};

export default Sidebar;

const SidebarSection = styled.section`
  min-height: 100vh;
  margin-left: 0;
  width: 18%;
  background-color: #eeeeee;
  position: relative;
`;

const SidebarTitle = styled.h1`
  margin-top: 32px;
  margin-bottom: 64px;
  text-align: center;
  color: #4db6ac;
`;

const SidebarItemContainer = styled.div`
  display: flex;
`;
