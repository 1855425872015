import React from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";

type ButtonMinusProps = {
  onClick: any;
  disabled: boolean;
};

const ButtonMinus = ({ onClick, disabled }: ButtonMinusProps) => {
  return (
    <ButtonContainer
      variant="outline-danger"
      className="shadow-none"
      onClick={onClick}
      disabled={disabled}
    >
      <ButtonLabel>- REMOVE</ButtonLabel>
    </ButtonContainer>
  );
};

export default ButtonMinus;

const ButtonContainer = styled(Button)`
  background-color: #ffffff;
  display: flex;
  margin-left: 23px;
  height: 36px;

  &:disabled {
    background-color: #ffffff;
  }
`;

const ButtonLabel = styled.label`
  margin-left: 8px;
  margin-right: 8px;
  vertical-align: middle;

  &:hover {
    cursor: pointer;
  }
`;
