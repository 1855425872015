import React, { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import SelectBox from "./SelectBox";
import { SelectOption } from "./types";
import { DefaultHash, DefaultMessage, OptionsHashData } from "./data";

type HashDataProps = {
  value: string;
  handleValue: (value: string) => void;
};

const HashData = ({ value, handleValue }: HashDataProps) => {
  const [hashDataOpt, setHashDataOpt] = useState<SelectOption>(
    OptionsHashData[0]
  );

  const handleHashDataOpt = (option: SelectOption) => {
    setHashDataOpt(option);

    if (option.value === "hash") {
      handleValue(DefaultHash);
    } else {
      handleValue(DefaultMessage);
    }
  };

  return (
    <>
      <ContentRow>
        <Col>
          <label>hashData JSON architecture</label>
          <SelectBox
            options={OptionsHashData}
            handleOptions={handleHashDataOpt}
          />
        </Col>
      </ContentRow>

      <ContentRow>
        <Col>
          <label>hashData JSON Value</label> <br />
          {hashDataOpt.value === "message" && (
            <Warning>{`* 'bool' type은 [string]이 아닌 [boolean] 값으로 입력하세요.("true" -> true, "false" -> false)`}</Warning>
          )}
          <Textarea
            minRows={7}
            value={value}
            onChange={(e) => handleValue(e.target.value)}
          />
        </Col>
      </ContentRow>
    </>
  );
};

export default HashData;

const ContentRow = styled(Row)`
  width: 100%;
  margin-bottom: 8px;
`;

const Textarea = styled(TextareaAutosize)`
  width: 100%;
  border: 1px solid #dbead5;
`;

const Warning = styled.label`
  color: red;
`;
