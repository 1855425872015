import React from "react";
import { Button } from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs";
import styled from "styled-components";

type AddButtonProps = {
  onClick: any;
};

const ButtonAdd = ({ onClick }: AddButtonProps) => {
  return (
    <ButtonContainer
      variant="outline-success"
      className="shadow-none"
      onClick={onClick}
    >
      <PlusIcon />
      <ButtonLabel>ADD</ButtonLabel>
    </ButtonContainer>
  );
};

export default ButtonAdd;

const ButtonContainer = styled(Button)`
  display: flex;
  margin-left: 23px;
  height: 36px;
  border: 1px solid #1aab8a;
  color: #1aab8a;

  &:hover {
    background-color: #1aab8a;
  }
`;

const ButtonLabel = styled.label`
  margin-left: 8px;
  margin-right: 8px;
  vertical-align: middle;

  &:hover {
    cursor: pointer;
  }
`;

const PlusIcon = styled(BsPlusLg)`
  height: 100%;
  margin: 0 auto;
`;
