import React from "react";
import styled from "styled-components";

const Footer = () => (
  <FooterContainer>ⓒ Wemade Co., Ltd. All rights reserved.</FooterContainer>
);

export default Footer;

const FooterContainer = styled.footer`
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  margin-bottom: 14px;
`;
