import React from "react";
import styled from "styled-components";

type InputTextProps = {
  label: string;
  value?: string;
  disabled: boolean;
  onChange?: any;
};

const InputText = ({ label, value, disabled, onChange }: InputTextProps) => {
  return (
    <InputTextContainer>
      <InputTextLabel>{label}</InputTextLabel>
      <InputTextContent
        type="text"
        id={label}
        value={value || ""}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
      />
    </InputTextContainer>
  );
};

export default InputText;

const InputTextContainer = styled.div`
  margin-left: 0;
  margin-top: 13px;
`;

const InputTextLabel = styled.label``;

const InputTextContent = styled.input`
  width: 100%;
  height: 36px;
  border: 1px solid #e9e9e9;
  border-bottom: thick double #dbead5;
  padding-left: 2px;

  :disabled {
    background-color: #dcdcdc;
  }
`;
