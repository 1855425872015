import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import sign from "jwt-encode";
import TextareaAutosize from "react-textarea-autosize";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { DefaultHash, OptionsRedirectUri, OptionsSignType } from "./data";
import InputText from "./InputText";
import SelectBox from "./SelectBox";
import SigningType from "./SigningType";
import {
  HashMessageData,
  LoginInfo,
  SelectOption,
  SigningReq,
  Sign,
} from "./types";
import IframeModal from "./IframeModal";
import ButtonAdd from "./button/ButtonAdd";
import ButtonMinus from "./button/ButtonMinus";
import ButtonBasic from "./button/ButtonBasic";

type SigningProps = {
  loginInfo?: LoginInfo;
};

const Signing = ({ loginInfo }: SigningProps) => {
  const location = useLocation();
  const defaultSignData: Sign = {
    type: OptionsSignType[0],
    value: DefaultHash,
  };
  const [redirectUriOpt, serRedirectUriOpt] = useState<SelectOption>(
    OptionsRedirectUri[0]
  );
  const [redirectUri, setRedirectUri] = useState<string>(
    `${window.location.href}info`
  );
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [signature, setSignature] = useState<string>();
  const [jwt, setJwt] = useState<string>("");
  const [signDataArr, setSignDataArr] = useState<Sign[]>([defaultSignData]);
  const [jsonError, setJsonError] = useState<string>("");
  const [typeList, setTypeList] = useState<number[]>([0]);

  const handleRedirectUriOpt = (option: SelectOption) => {
    serRedirectUriOpt(option);
  };

  const handleRedirectUri = (uri: string) => {
    setRedirectUri(uri);
  };

  const handleModal = (state: boolean) => {
    setModalIsOpen(state);
  };

  const handleSignature = (newSignature: string[]) => {
    setSignature(newSignature.join(",\n"));
  };

  const handleSignData = (index: number, newSign: Sign) => {
    setSignDataArr((state: Sign[]) => [
      ...state.slice(0, index),
      newSign,
      ...state.slice(index + 1),
    ]);
  };

  const handleJwt = useCallback(
    (newJwt: string) => {
      let baseUri = process.env.REACT_APP_WEB_AUTH_ALPHA;
      if (loginInfo?.environment === "dev") {
        baseUri = process.env.REACT_APP_WEB_AUTH_DEV;
      } else if (loginInfo?.environment === "stage") {
        baseUri = process.env.REACT_APP_WEB_AUTH_STAGE;
      }

      if (redirectUriOpt.value === "none") {
        setJwt(`${baseUri}/txSigning?jwt=${newJwt}`);
      } else {
        setJwt(
          `${baseUri}/txSigning?response_mode=${redirectUriOpt.value}&redirect_uri=${redirectUri}&jwt=${newJwt}`
        );
      }
    },
    [loginInfo?.environment, redirectUri, redirectUriOpt.value]
  );

  const handleJsonError = (err: string) => {
    setJsonError(err);
  };

  const btnSignOnClick = () => {
    try {
      if (!loginInfo?.accessTokenV1) {
        alert(`login 먼저 해주세요.`);
        return null;
      }

      const values: HashMessageData[] = [];
      const types: number[] = [];
      const memos: string[] = [];
      const themes: string[] = [];

      for (const data of signDataArr) {
        values.push(JSON.parse(data.value)[0]);
        types.push(Number(data.type.value));
        memos.push(data.memo || "");

        // theme default 값 지정
        themes.push(
          data.theme && data.theme.value
            ? data.theme.value === "none"
              ? "red"
              : data.theme.value
            : ""
        );
      }

      if (location.pathname === '/auth') {
        const reqObj: SigningReq = {
          accessToken: loginInfo?.accessTokenV1 || "",
          address: loginInfo?.address || "",
          hashData: values,
          type: types,
          memo: memos || null,
          theme: themes || null,
      };

        const jwt = sign(reqObj, process.env.REACT_APP_SECRET || "secret");
        handleJwt(jwt);
        setModalIsOpen(true);
      } else {
        let reqObj;
        if ('hash' in values[0]) {
          reqObj = window.wemix().requestSignature(values.map(value => value.hash));
        } else {
          reqObj = window.wemix().requestMessageSignature(null,  null,  values);
        }
        window.wemix().openQR('sign', reqObj, (success: any)=> {
          setSignature(success.map((s:any) => s.data));
        }, (fail: any) => {console.log(fail, 'sign fail')})
      }
    } catch (error: any) {
      console.log(error.message);
      handleJsonError(error.message);
    }
  };

  const addSigningType = () => {
    let list = [...typeList];
    let listCounter = list.slice(-1)[0];
    listCounter += 1;
    list.push(listCounter); // index 사용 X
    setTypeList(list);

    // hash type data default value 추가
    setSignDataArr((state: Sign[]) => [...state, defaultSignData]);
  };

  const removeSigningType = (target: number) => {
    const newTypeList: number[] = [];
    for (let i = 0; i < typeList.length - 1; i++) {
      newTypeList.push(i);
    }
    setTypeList(newTypeList);

    const newSignArr = signDataArr.filter((data, index) => index !== target);

    setSignDataArr([...newSignArr]);
  };

  useEffect(() => {}, [redirectUriOpt, handleJwt, typeList, signDataArr]);

  return (
    <>
      <IframeModal
        title="Web Auth Transaction Signing"
        isOpen={modalIsOpen}
        uri={jwt}
        environment={loginInfo?.environment || "alpha"}
        handlModalIsOpen={handleModal}
        handleSignature={handleSignature}
      />
      <SigningSection>
        <SigningContent>
          <SigningHeader>
            <SigningSubTitle>2.1. redirect uri</SigningSubTitle>
          </SigningHeader>
          <ContentRow>
            <Col>
              <SigningLabel>response mode</SigningLabel>
              <SelectBox
                options={OptionsRedirectUri}
                handleOptions={handleRedirectUriOpt}
              />
            </Col>
          </ContentRow>
          {redirectUriOpt.value === "query" && (
            <ContentRow>
              <Col>
                <InputText
                  label="redirect uri"
                  value={redirectUri}
                  disabled={false}
                  onChange={handleRedirectUri}
                />
              </Col>
            </ContentRow>
          )}
          <SigningHeader>
            <SigningSubTitle>2.2. sign type </SigningSubTitle>
            <ButtonAdd onClick={addSigningType} />
          </SigningHeader>

          {signDataArr &&
            signDataArr.map((data: Sign, index: number) => (
              <SigningTypeContainer key={index}>
                <Tag>
                  <TagName>Sign {index + 1}</TagName>
                  <ButtonMinus
                    disabled={typeList.length === 1 && index === 0}
                    onClick={() => removeSigningType(index)}
                  />
                </Tag>
                <SigningType
                  index={index}
                  signData={data}
                  errorMessage={jsonError}
                  handleSignData={handleSignData}
                  handleJsonError={handleJsonError}
                />
              </SigningTypeContainer>
            ))}

          {jsonError && (
            <ErrorMessage>
              <span className="text-danger">JSON error</span> <br />
              <span className="text-warning">
                <BsFillExclamationTriangleFill className="text-warning" />
                {jsonError}
              </span>
              <br />
              <span>
                if you want to validate your JSON code, Paste it in{" "}
                <a
                  href="https://jsonlint.com/"
                  target="_blank"
                  className="text-info"
                  rel="noreferrer"
                >
                  JSON validator
                </a>
              </span>
            </ErrorMessage>
          )}

          <SignButton>
            <ButtonBasic label="SIGN" onClick={btnSignOnClick} />
          </SignButton>

          <ContentRow>
            <Col>
              {
                location.pathname === '/auth' && <InputText
                  label="request txSign url for WebView"
                  value={jwt}
                  disabled={true}
              />
              }
            </Col>
          </ContentRow>
          <ContentRow>
            <Col>
              <SignatureLabel>signature</SignatureLabel>
              <Textarea minRows={1} value={signature} disabled={true} />
            </Col>
          </ContentRow>
        </SigningContent>
      </SigningSection>
    </>
  );
};

export default Signing;

const SigningSubTitle = styled.h4``;

const SigningLabel = styled.label``;

const SigningSection = styled.section`
  margin-bottom: 64px;
`;

const SigningContent = styled.div`
  width: 100%;
`;

const ContentRow = styled(Row)`
  width: 100%;
`;

const SigningHeader = styled.div`
  margin-top: 32px;
  display: flex;
`;

const SigningTypeContainer = styled.div`
  margin-top: 32px;
  padding: 13px;
  border: 1px solid #dbead5;
  position: relative;
`;

const SignButton = styled.div`
  margin-top: 11px;
`;

const Tag = styled.div`
  display: flex;
  position: absolute;
  left: 87%;
  top: -17px;
  text-align: center;
  color: #4db6ac;
  height: 36px;
  width: 236px;
`;

const TagName = styled.label`
  background-color: #ffffff;
  font-size: 1.3em;
  vertical-align: middle;
`;

const ErrorMessage = styled.div`
  margin-top: 3px;
  padding: 13px;
  border: 1px solid #dbead5;
  font-size: 13px;
`;

const SignatureLabel = styled.label`
  margin-top: 13px;
`;

const Textarea = styled(TextareaAutosize)`
  width: 100%;
  padding: 5px 0 5px 2px;
  border: 1px solid #dbead5;
  resize: none;
`;
