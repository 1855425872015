import React, { useState } from "react";
import styled from "styled-components";
import Login from "../components/Login";
import Signing from "../components/Signing";
import { LoginInfo } from "../components/types";

const Auth = () => {
  const [loginInfo, setLoginInfo] = useState<LoginInfo>();

  const handleLoginInfo = (info: LoginInfo) => {
    setLoginInfo(info);
  };

  return (
    <AuthSection>
      <AuthTitle>1. LOGIN</AuthTitle>
      <Login loginInfo={loginInfo} handleLoginInfo={handleLoginInfo} />

      <AuthTitle>2. Tx Signing</AuthTitle>
      <Signing loginInfo={loginInfo} />
    </AuthSection>
  );
};

export default Auth;

const AuthSection = styled.section`
  height: 100%;
  width: 100%;
  padding: 32px;
`;

const AuthTitle = styled.h1`
  text-decoration: underline;
  margin-bottom: 32px;
`;
