import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { MenuItem } from "./types";

type MenuProps = {
  menu: MenuItem;
};

const SidebarItem = ({ menu }: MenuProps) => {
  return (
    <ItemContainer
      to={menu.path}
      className={({ isActive }) => (isActive ? "active" : "inactive")}
    >
      {menu.name}
    </ItemContainer>
  );
};

export default SidebarItem;

const ItemContainer = styled(NavLink)`
  text-align: center;
  width: 100%;
  padding: 10px;
  font-size: 1.8em;
  color: #000000;

  &:hover {
    color: #00b981;
  }
`;
