import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import axios from "axios";
import ButtonBasic from "./button/ButtonBasic";
import InputText from "./InputText";
import IframeModal from "./IframeModal";
import SelectBox from "./SelectBox";
import { LoginInfo, SelectOption } from "./types";
import { OptionsEnvironment } from "./data";


type LoginProps = {
  loginInfo?: LoginInfo;
  setEnv?: (env: any) => void;
  handleLoginInfo: (info: LoginInfo) => void;
};

const Login = ({ loginInfo, setEnv, handleLoginInfo }: LoginProps) => {
  const location = useLocation();
  const [environmentOpt, setEnvironmentOpt] = useState<SelectOption>(
    OptionsEnvironment[0]
  );
  const [uri, setUri] = useState(process.env.REACT_APP_WEB_AUTH_ALPHA + "/run");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleV1Info = (info: LoginInfo) => {
    handleLoginInfo({ ...info, environment: environmentOpt.value });
  };

  const handleUserId = (id: string) => {
    if (!!loginInfo) {
      handleLoginInfo({ ...loginInfo, userID: id });
    } else {
      handleLoginInfo({
        userID: id,
        accessTokenV1: "",
        accessTokenV2: "",
        address: "",
        environment: "",
      });
    }
  };

  const handleEnvironment = (changeEnvironment: SelectOption) => {
    setEnvironmentOpt(changeEnvironment);

    if (changeEnvironment.value === "dev") {
      setUri(`${process.env.REACT_APP_WEB_AUTH_DEV}/run`);
      window.wemix().setEnv({
        webauth: "https://dev-webauth.wemixnetwork.com",
        oauth: "https://dev-oauth.wemixnetwork.com",
        wallet: "https://dev-dapp.wemixnetwork.com"
      })
    } else if (changeEnvironment.value === "stage") {
      setUri(`${process.env.REACT_APP_WEB_AUTH_STAGE}/run`);
      window.wemix().setEnv({
        webauth: "https://stg-webauth.wemixnetwork.com",
        oauth: "https://stg-oauth.wemixnetwork.com",
        wallet: "https://stg-dapp.wemixnetwork.com"
      })
    } else if (changeEnvironment.value === "PROD") {
      setUri(`${process.env.REACT_APP_WEB_AUTH_PROD}/run`);
      window.wemix().setEnv({
        webauth: "https://webauth.wemixnetwork.com",
        oauth: "https://oauth.wemixnetwork.com",
        wallet: "https://dapp.wemixnetwork.com"
      })
    } else {
      setUri(`${process.env.REACT_APP_WEB_AUTH_ALPHA}/run`);
      window.wemix().setEnv({
        webauth: "https://alpha-webauth.wemix.co",
        oauth: "https://alpha-oauth.wemix.co",
        wallet: "https://alpha-dapp.wemix.co"
      })
    }
    if (setEnv) {
      setEnv(window.wemix().env())
    }
    handleLoginInfo({userID: '',environment: '',accessTokenV2:'',accessTokenV1:'',address:''});
  };

  const handleModal = (state: boolean) => {
    setModalIsOpen(state);
  };

  const fectchDevSignin = useCallback(async () => {
    const env = environmentOpt.value;
    const accessToken = loginInfo?.accessTokenV1;

    // endpoint 지정
    let uri = process.env.REACT_APP_AUTH_ALPHA;
    let clientId = process.env.REACT_APP_AUTH_ALPHA_CLIENT_ID;
    let clientSecret = process.env.REACT_APP_AUTH_ALPHA_CLIENT_SECRET;

    if (env === "dev") {
      uri = process.env.REACT_APP_AUTH_DEV;
    } else if (env === "stage") {
      uri = process.env.REACT_APP_AUTH_STAGE;
    } else if (env === "PROD") {
      uri = process.env.REACT_APP_AUTH_PROD;
    }

    const req = axios.create({
      baseURL: uri,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    // a2a prepare
    const requestId = await req
      .post(`/api/v2/a2a/prepare`, {
        client_id: clientId,
        client_secret: clientSecret,
        type: "auth",
      })
      .then((res) => {
        return res.data.request_id;
      })
      .catch((error) => {
        alert(`Failed to prepare. \n${error}`);
        process.exit(1);
      });

    // a2a consent
    await req
      .get(`/api/v2/a2a/consent`, {
        params: {
          client_id: clientId,
          client_secret: clientSecret,
          request_id: requestId,
        },
      })
      .catch((err) => {
        alert(`Failed to consent. \n${err}`);
        process.exit(1);
      });

    // a2a confirm
    await req
      .post(`/api/v2/a2a/auth/${clientId}`, {
        client_id: clientId,
        client_secret: clientSecret,
        request_id: requestId,
      })
      .catch((err) => {
        alert(`Failed to auth. \n${err}`);
        process.exit(1);
      });

    const code = await req
      .get(`/api/v2/a2a/result`, {
        params: {
          client_id: clientId,
          client_secret: clientSecret,
          request_id: requestId,
        },
      })
      .then((res) => {
        return res.data.result;
      })
      .catch((err) => {
        alert(`Failed to get result. \n${err}`);
        process.exit(1);
      });

    // access token 발급
    await req
      .post(`${uri}/api/v2/oauth/token`, {
        client_id: clientId,
        client_secret: clientSecret,
        code: code,
        grant_type: `code`,
      })
      .then((res) => {
        if (!!loginInfo) {
          handleLoginInfo({
            ...loginInfo,
            accessTokenV2: res.data.access_token,
            environment: env,
          });
        }
      })
      .catch((error) => {
        alert(`Failed to get access token. \n ${error}`);
        process.exit(1);
      });
  }, [handleLoginInfo, loginInfo]);

  const btnLoginOnClick = () => {
    if (location.pathname === "/auth") setModalIsOpen(true);
    else {
      window.wemix().openQR('auth', null, async (success: any) => {
        const info = await window.wemix().login()
        handleLoginInfo({userID: info.data.userID, address: info.data.address, accessTokenV1: success.data.access_token, accessTokenV2: success.data.refresh_token, environment: environmentOpt.value})
      }, (fail: any) => {console.log(fail)});
    }
  };

  useEffect(() => {
    if (loginInfo?.accessTokenV1 && !loginInfo?.accessTokenV2) {
      fectchDevSignin();
    }
  }, [
    fectchDevSignin,
    loginInfo?.accessTokenV1,
    loginInfo?.accessTokenV2,
    loginInfo?.environment,
  ]);

  return (
    <>
      <IframeModal
        title="Web Auth Login"
        isOpen={modalIsOpen}
        uri={uri}
        environment={environmentOpt.value}
        handlModalIsOpen={handleModal}
        handleLoginInfo={handleV1Info}
      />
      <LoginSection>
        <LoginInput>
          <SelectBox
            options={window.location.host.includes('alpha') ? OptionsEnvironment : OptionsEnvironment.filter(o => o.label !== 'PROD')}
            handleOptions={handleEnvironment}
            width="13%"
          />
          <ButtonBasic label="Login" onClick={btnLoginOnClick} />
        </LoginInput>
        <LoginContent>
          <ContentRow>
            <Col>
              <InputText
                label="user id"
                value={loginInfo?.userID}
                disabled={true}
                onChange={handleUserId}
              />
            </Col>
            <Col>
              <InputText
                label="address"
                value={loginInfo?.address}
                disabled={true}
              />
            </Col>
          </ContentRow>
          <ContentRow>
            <Col>
              <InputText
                label="v1 access token"
                value={loginInfo?.accessTokenV1}
                disabled={true}
              />
            </Col>
          </ContentRow>
          <ContentRow>
            <Col>
              <InputText
                label="v2 access token"
                value={loginInfo?.accessTokenV2}
                disabled={true}
              />
            </Col>
          </ContentRow>
        </LoginContent>
      </LoginSection>
    </>
  );
};

export default Login;

const LoginSection = styled.section`
  margin-bottom: 64px;
`;

const LoginInput = styled.div`
  display: flex;
  margin-bottom: 23px;
`;

const LoginContent = styled.div`
  width: 100%;
`;

const ContentRow = styled(Row)`
  width: 100%;
`;
