// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/INFINITYSANSR-REGULAR.TTF", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/INFINITYSANS-BOLD.TTF", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"InfinitySansReg\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n@font-face {\n  font-family: \"InfinitySanBold\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n", "",{"version":3,"sources":["webpack://./src/assets/css/font.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,4CAA8C;AAChD;;AAEA;EACE,8BAA8B;EAC9B,4CAA0C;AAC5C","sourcesContent":["@font-face {\n  font-family: \"InfinitySansReg\";\n  src: url(\"../fonts/INFINITYSANSR-REGULAR.TTF\");\n}\n\n@font-face {\n  font-family: \"InfinitySanBold\";\n  src: url(\"../fonts/INFINITYSANS-BOLD.TTF\");\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
