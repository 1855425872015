import React, { useEffect, useState} from "react";
import styled from "styled-components";
import Login from "../components/Login";
import Signing from "../components/Signing";
import { LoginInfo } from "../components/types";
import axios from "axios";

const QRAuth = () => {
    const [loginInfo, setLoginInfo] = useState<LoginInfo>();
    const [env, setEnv] = useState({});

    useEffect(() => {
        const client_secret = process.env.REACT_APP_AUTH_ALPHA_CLIENT_SECRET;
        window.wemix().setRequestPreparedFunction(async (param: any) => {
            return await axios.post(`${window.wemix().env()['oauth']}/api/v2/a2a/prepare`, {
                ...param, client_secret
            })
        })
        window.wemix().setRequestTokenFunction(async (param: any) => {
            return await axios.post(`${window.wemix().env()['oauth']}/api/v2/oauth/token`, {
                ...param, client_secret
            })
        })
        window.wemix().setRequestSendSignedTx((hash: any,  signature: any) => {
            return Promise.resolve({data: {Result: 0, data: signature}});
        })
    },[env]);

    const handleLoginInfo = (info: LoginInfo) => {
        setLoginInfo(info);
    };

    const handleEnv =(env : any) =>{
        setEnv(env);
    }

    return (
        <AuthSection>
            <AuthTitle>1. LOGIN</AuthTitle>
            <Login loginInfo={loginInfo} setEnv={handleEnv} handleLoginInfo={handleLoginInfo} />

            <AuthTitle>2. Tx Signing</AuthTitle>
            <Signing loginInfo={loginInfo} />
        </AuthSection>
    );
};

export default QRAuth;

const AuthSection = styled.section`
  height: 100%;
  width: 100%;
  padding: 32px;
`;

const AuthTitle = styled.h1`
  text-decoration: underline;
  margin-bottom: 32px;
`;
