import React from "react";
import styled from "styled-components";

const Tutorial = () => (
  <TutorialSection>
    <h1>Comming Soon...</h1>
  </TutorialSection>
);

export default Tutorial;

const TutorialSection = styled.section`
  width: 100%;
  height: 100vh;
  padding: 32px;
`;
