import React from "react";
import styled from "styled-components";
import Select from "react-select";
import { SelectOption } from "./types";

type SelectProps = {
  options: SelectOption[];
  handleOptions?: any;
  width?: string;
  value?: SelectOption;
};

const SelectBox = ({ options, handleOptions, width, value }: SelectProps) => {
  const style = {
    width: width || "100%",
  };

  return (
    <SelectContainer style={style}>
      <Select
        defaultValue={options[0]}
        options={options}
        onChange={(e) => handleOptions(e)}
        value={value}
      />
    </SelectContainer>
  );
};

export default SelectBox;

const SelectContainer = styled.div`
  margin-right: 7px;
`;
