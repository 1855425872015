import React from "react";
import styled from "styled-components";

const NotFound = () => (
  <NotFoundSection>
    <h1>404 Page Not Found</h1>
  </NotFoundSection>
);

export default NotFound;

const NotFoundSection = styled.section`
  width: 100%;
  padding: 32px;
`;
