import { createGlobalStyle } from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    list-style: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    font-family: "InfinitySansReg";
  }

  body, html {
    height: 100%;
    width: 100%;
  }

  a {
    text-decoration: none;
  }


  label {
    font-size: 15px;
  }

  .active {
    text-decoration: green wavy underline;
  }
`;

export default GlobalStyle;
