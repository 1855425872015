import React from "react";
import styled from "styled-components";
import GlobalStyle from "../../GlobalStyle";
import Router from "../../router/Router";

const MainLayout = () => (
  <>
    <GlobalStyle />
    <MainContainer>
      <Router />
    </MainContainer>
  </>
);

export default MainLayout;

const MainContainer = styled.div`
  height: 100%;
  display: flex;
`;
