import { SelectOption } from "./types";

export const OptionsEnvironment: SelectOption[] = [
  {
    label: "Alpha",
    value: "alpha",
  },
  {
    label: "Dev",
    value: "dev",
  },
  {
    label: "Stage",
    value: "stage",
  },
  {
    label: "PROD",
    value: "PROD",
  },
];

export const OptionsRedirectUri: SelectOption[] = [
  {
    label: "none",
    value: "none",
  },
  {
    label: "query",
    value: "query",
  },
];

export const OptionsHashData: SelectOption[] = [
  {
    label: "hash",
    value: "hash",
  },
  {
    label: "message",
    value: "message",
  },
];

export const OptionsSignType: SelectOption[] = [
  {
    label: "Authentication",
    value: "1",
  },
  {
    label: "Message Sign",
    value: "2",
  },
  {
    label: "Transaction Sign",
    value: "3",
  },
];

export const OptionsTheme: SelectOption[] = [
  {
    label: "none",
    value: "none",
  },
  {
    label: "red",
    value: "red",
  },
  {
    label: "yellow",
    value: "yellow",
  },
  {
    label: "green",
    value: "green",
  },
];

export const DefaultHash: string = `[{ "hash": "0x9200250d1651203a48a39df7ec90710200655e5b0c1eb6dc98a1df2d6f669fd1" }]`;

export const DefaultMessage: string = `[{
  "message": [
    {"type": "", "value": "", "name": ""},
    {"type": "", "value": "", "name": ""},
    {"type": "", "value": "", "name": ""}
  ],
  "chain": ""
}]`;
