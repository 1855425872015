import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import HashData from "./HashData";
import InputText from "./InputText";
import SelectBox from "./SelectBox";
import { SelectOption, Sign } from "./types";
import { DefaultHash, OptionsSignType, OptionsTheme } from "./data";

type SigningTypeProps = {
  index: number;
  signData: Sign;
  errorMessage: string;
  handleSignData: (index: number, sign: Sign) => void;
  handleJsonError: (err: string) => void;
};

const SigningType = ({
  index,
  signData,
  errorMessage,
  handleSignData,
  handleJsonError,
}: SigningTypeProps) => {
  const [, setTypeOpt] = useState<SelectOption>(OptionsSignType[0]);
  const [, setMemo] = useState<string>("");
  const [, setThemeOpt] = useState<SelectOption>(OptionsTheme[0]);
  const [, setValue] = useState<string>(DefaultHash);

  const handleTypeOpt = (option: SelectOption) => {
    setTypeOpt(option);
    handleSignData(index, { ...signData, type: option });
  };

  const handleMemo = (newMemo: string) => {
    setMemo(newMemo);
    handleSignData(index, { ...signData, memo: newMemo });
  };

  const handleThemeOpt = (option: SelectOption) => {
    setThemeOpt(option);
    handleSignData(index, { ...signData, theme: option });
  };

  const handleValue = (newValue: string) => {
    setValue(newValue);
    handleSignData(index, { ...signData, value: newValue });

    if (errorMessage !== "") {
      handleJsonError("");
    }
  };

  return (
    <SigningTypeSection>
      <ContentRow>
        <Col>
          <SigningTypeLabel>sign type</SigningTypeLabel>
          <SelectBox
            value={signData.type}
            options={OptionsSignType}
            handleOptions={handleTypeOpt}
          />
        </Col>
      </ContentRow>

      <ContentRow>
        <Col>
          <InputText
            label="sign memo"
            value={signData.memo}
            disabled={false}
            onChange={handleMemo}
          />
        </Col>
      </ContentRow>

      <ContentRow>
        <Col>
          <SigningTypeLabel>theme</SigningTypeLabel>
          <SelectBox
            value={signData.theme}
            options={OptionsTheme}
            handleOptions={handleThemeOpt}
          />
        </Col>
      </ContentRow>

      <HashData value={signData.value} handleValue={handleValue} />
    </SigningTypeSection>
  );
};

export default SigningType;

const SigningTypeSection = styled.section``;

const SigningTypeLabel = styled.label``;

const ContentRow = styled(Row)`
  width: 100%;
  margin-bottom: 8px;
`;
