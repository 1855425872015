import React from "react";
import { Route } from "react-router";
import { BrowserRouter, Routes } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import NotFound from "../pages/404";
import Auth from "../pages/Auth";
import Info from "../pages/Info";
import Tutorial from "../pages/Tutorial";
import QRAuth from "../pages/QRAuth";

const Router = () => (
  <BrowserRouter>
    <Sidebar />
    <Routes>
      <Route path="/" element={<QRAuth />}/>
      <Route path="/auth" element={<Auth />} />
      <Route path="/tutorial" element={<Tutorial />} />
      <Route path="/info" element={<Info />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);

export default Router;
