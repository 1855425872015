import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import InputText from "../components/InputText";

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const Info = () => {
  let query = useQuery();

  return (
    <InfoSection>
      <InfoTitle>Redirect Info</InfoTitle>
      <ContentRow>
        <Col>
          <InputText
            label="result"
            value={query.get("result") || ""}
            disabled={true}
          />
        </Col>
      </ContentRow>
    </InfoSection>
  );
};

export default Info;

const InfoSection = styled.section`
  width: 100%;
  padding: 32px;
  height: 100vh;
`;

const InfoTitle = styled.h1`
  text-decoration: underline;
  margin-bottom: 32px;
`;

const ContentRow = styled(Row)`
  width: 100%;
`;
