import React from "react";
import styled from "styled-components";

type ButtonProps = {
  label: string;
  onClick: any;
};

const ButtonBasic = ({ label, onClick }: ButtonProps) => (
  <Button onClick={onClick}>{label}</Button>
);

export default ButtonBasic;

const Button = styled.button`
  background: #1aab8a;
  color: #fff;
  border: none;
  position: relative;
  height: 38px;
  font-size: 1em;
  padding: 0 2em;
  cursor: pointer;
  transition: 800ms ease all;
  outline: none;

  &:hover {
    background: #fff;
    color: #1aab8a;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 2px;
    width: 0;
    background: #1aab8a;
    transition: 400ms ease all;
  }

  &:after {
    right: inherit;
    top: inherit;
    left: 0;
    bottom: 0;
  }

  &:hover:before,
  &:hover:after {
    width: 100%;
    transition: 800ms ease all;
  }
`;
